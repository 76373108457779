*,
*::before,
*::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    font-size: 62.5%;
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif !important;
    font-weight: normal !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

li {
    list-style: none;
}

/* rich text formatting */
.prose ul, .prose ol{
    padding-left: 1.8rem;
}

.prose li {
    list-style: unset;
}

.prose p {
    min-height: 1.8rem;
}
